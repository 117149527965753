import {
  Box,
  Message,
  ProfileHeder,
  InputModern,
  Button,
  StickersGroup,
  PageWrap,
  AddFileModal,
  ModalAlert,
  PanelWithButton,
  SendWinkBar,
} from "components/atomsKit/Elements";

import {
  Timeonchat,
  Image,
  NameonChat,
  Textonchat,
} from "components/designAtom/Elements";

import BanersSubPage from "pages/BanersSubPage/BanersSubPage";

import { stickersConfig } from "img/stickers/stikers";

import useMessagerPage from "./useMessagerPage";
import usePrice from "hooks/usePrice";
import useHandleActiveBlocks from "hooks/useHandleActiveBlocks";

import { cutTextforToltip } from "utils/common";

import { MIRROR } from "data/mirrorConfig";

// @ts-ignore
import classes from "./MessagerPage.module.scss";

const MessagerPage = () => {
  const {
    womanData,
    chatInfo,
    chats,
    valueMessage,
    handleValueMessage,
    onChangeSendMessage,
    wrapListRef,
    togleIsOpenStickersBox,
    setTogleIsOpenStickersBox,
    onChangeStiker,
    handleFavorite,
    isOpenModaAddFile,
    setIsOpenModaAddFile,
    user_avatar,
    user_name,
    scrollDown,
    navConfig,
    handleUploadNavBtn,
    handleOpenUpploadFile,
    uploadFiles,
    handleItemMedia,
    selectedMedia,
    handleSendMedias,
    handleUploadFile,
    handleDelMedia,
    handleDropBtnLeter,
    handleDropBtnBlock,
    handleDropBtnComplain,
    isOpenModalAddBlock,
    setIsOpenModalAddBlock,
    handleAddBlock,
    isOpenModalComplaint,
    setIsOpenModalComplaint,
    changeFileToComplaint,
    selectFileToComplaint,
    setSelectFileToComplaint,
    handleMail,
    mail,
    onChangeDescribe,
    describe,
    sendSupportReq,
    handleProfile,
    handleContent,
    handleSendGift,
    sayHalloList,
    handlePlayAudio,
    onChangeSetLike,
  } = useMessagerPage();

  const price = usePrice();

  const { isHideGift } = useHandleActiveBlocks();

  return (
    <>
      <ModalAlert
        modalIsOpen={isOpenModalAddBlock}
        closeModal={() => setIsOpenModalAddBlock(false)}
        icon="x1"
        allertMessage={"Do you really want to add her to the block list?"}
      >
        <Box flex width100 justify={"center"} directionColumn align="center">
          <NameonChat
            textAlign="center"
            text={`You won't receive messages from herany longer. To unblock the member, go to "Block list" menu section in your profile`}
          />
          <Button
            margin="20px 0 0 0"
            padding="0 20px"
            onClick={handleAddBlock}
            text={"Аdd to block list"}
            icon="x1"
            fitContent
          />
          <Button
            margin="10px 0 0 0"
            padding="0 20px"
            onClick={() => setIsOpenModalAddBlock(false)}
            text={"Cancel"}
            fitContent
            isWhite
            borderOff
          />
        </Box>
      </ModalAlert>
      <ModalAlert
        modalIsOpen={isOpenModalComplaint}
        closeModal={() => setIsOpenModalComplaint(false)}
        icon="x1"
        allertMessage={"Complaint"}
      >
        <form onSubmit={sendSupportReq}>
          <InputModern
            type="email"
            placeholder="Your E-Mail"
            value={mail}
            onChange={(e) => {
              handleMail(e.target.value);
            }}
          />
          <Textonchat
            text={`Member ID: ${womanData?.public_external_id}`}
            size={12}
            isSpan
            margin="20px 0 20px 0"
            className={classes.text}
          />
          <InputModern
            isTextArea
            onChange={(e: any) => onChangeDescribe(e.target.value)}
            placeholder={"Please, describe the reason"}
            value={describe}
            className={classes.messageInput}
          />
          {!selectFileToComplaint ? (
            <label className={classes.uploadBtn}>
              <input
                style={{ display: "none" }}
                type="file"
                id="popup_inp_file1"
                onChange={changeFileToComplaint}
              />
              <Button
                margin="20px 0 0 0"
                iconSvg="support-file-ico"
                text="Choose your file, please"
                isWhite
                className={classes.btnDecor}
                type="button"
              />
            </label>
          ) : (
            <Box marginTop="10px" flex align={"center"} justify={"between"}>
              <Box flex align={"center"}>
                <Image name="file_uploaded" className={classes.imgUpload} />
                <Textonchat
                  // @ts-ignore
                  text={`File uploaded: ${cutTextforToltip(
                    // @ts-ignore
                    selectFileToComplaint?.target?.files[0]?.name,
                    10
                  )} (${Math.round(
                    // @ts-ignore
                    selectFileToComplaint?.target?.files[0]?.size / 1024
                  )} kb)`}
                  size={12}
                  isSpan
                  margin="0 0 0 10px"
                  className={classes.text}
                />
              </Box>
              <button onClick={() => setSelectFileToComplaint(undefined)}>
                <Image name="Trash-red" />
              </button>
            </Box>
          )}

          <p className={classes.ligalText}>
            <Timeonchat
              isSpan
              text="If you haven't mentioned your e-mail address during registration, please, contact us at "
            />
            <a
              href={`mailto:${
                // @ts-ignore
                MIRROR[process.env.REACT_APP_MIRROR_NAME].SUPPORT_MAIL
              }`}
              className={classes.mailLigal}
            >
              {/* @ts-ignore */}
              {MIRROR[process.env.REACT_APP_MIRROR_NAME].SUPPORT_MAIL}
            </a>
          </p>

          <Button
            margin="20px 0 0 0"
            padding="0 20px"
            text={"Send"}
            type="submit"
          />
        </form>
      </ModalAlert>
      <AddFileModal
        price={price}
        handleContent={handleContent}
        selectedMedia={selectedMedia}
        handleUploadFile={handleUploadFile}
        uploadFileNavConfig={navConfig}
        modalIsOpen={isOpenModaAddFile}
        closeModal={() => setIsOpenModaAddFile(false)}
        handleUploadNavBtn={handleUploadNavBtn}
        uploadFiles={uploadFiles}
        handleItemMedia={handleItemMedia}
        handleSendMedias={handleSendMedias}
        handleDelMedia={handleDelMedia}
      />

      <PageWrap startPositionY={86} height100>
        <>
          <div className={classes.baners}>
            <BanersSubPage />
            {womanData && (
              <ProfileHeder
                handleProfile={handleProfile}
                name={womanData?.name}
                age={womanData?.age}
                external_id={womanData?.external_id}
                public_external_id={womanData?.public_external_id}
                country={womanData?.country_code}
                city={womanData?.city_name}
                isOnline={womanData?.online === 0 ? false : true}
                isFavorire={womanData?.isFavorite}
                isLike={womanData?.isLike}
                imgLink={womanData?.avatar}
                handleFavorite={handleFavorite}
                country_code={`/assets/flags/${womanData?.country_code?.toLocaleLowerCase()}.jpg`}
                is_blocked={chatInfo?.femaleBlock}
                bodyMoreBtn={
                  <>
                    {!chatInfo?.femaleBlock ? (
                      <>
                        <Button
                          className={classes.btnDrop}
                          onClick={handleDropBtnLeter}
                          text="Write a letter"
                          isWhite
                          borderOff
                          iconSvg="mob-letter"
                          fitContent
                          bgImg
                          classNameIcon="btnIco"
                        />
                        <Button
                          className={classes.btnDrop}
                          onClick={handleDropBtnBlock}
                          text="Add to block list"
                          isWhite
                          borderOff
                          iconSvg="c3-top-block"
                          fitContent
                          bgImg
                          classNameIcon="btnIco"
                        />
                      </>
                    ) : (
                      <Button
                        className={classes.btnDrop}
                        onClick={() => handleDropBtnBlock("unblock")}
                        text="Remove from block list"
                        isWhite
                        borderOff
                        iconSvg="c3-top-block"
                        fitContent
                        color="#720066"
                      />
                    )}
                    <Button
                      className={classes.btnDrop}
                      onClick={handleDropBtnComplain}
                      text="To complain"
                      isWhite
                      borderOff
                      iconSvg="c3-top-attention"
                      fitContent
                    />
                  </>
                }
              />
            )}
            {!isHideGift && (
              <PanelWithButton
                buttonConfig={[
                  {
                    name: "Gift",
                    onClick: () => {
                      handleSendGift();
                    },
                  },
                ]}
              />
            )}
          </div>
          <div className={classes.pageContent}>
            {chats?.length === 0 ||
            (chats?.length === 1 && chats[0]?.message_content === "") ? (
              <Box
                flex
                directionColumn
                align="center"
                justify="center"
                height100
              >
                <Image name="empty-opened-chat" className={classes.emptyImg} />
                <Textonchat
                  text={"Your chat history is empty"}
                  size={14}
                  margin="10px 0 10px 0"
                />
                <Textonchat
                  text={"To show your interest, say hello to her"}
                  size={11}
                />
              </Box>
            ) : (
              <ul className={classes.wrap} id={"messagerBox"} ref={wrapListRef}>
                {chats
                  ?.slice()
                  .reverse()
                  .map((chat: any) => (
                    <li key={chat?.id} id={chat?.id}>
                      <Message
                        id={chat?.id}
                        filename={chat?.filename}
                        scrollDown={scrollDown}
                        avatar={
                          chat.is_male === 0 ? womanData?.avatar : user_avatar
                        }
                        user_name={user_name}
                        name={womanData?.name}
                        type={chat?.message_type}
                        text={chat?.message_content}
                        thumb_link={chat?.thumb_link}
                        isRead={chat?.read_status}
                        isInMessage={chat?.is_male === 0}
                        time={chat?.date_created}
                        handleContent={handleContent}
                        handlePlayAudio={handlePlayAudio}
                        payed={!!+chat?.payed}
                        price={price}
                      />
                    </li>
                  ))}
              </ul>
            )}
          </div>

          {/* !!!!!!!! ruls for show WINK  */}
          {((chatInfo && !chatInfo?.wink) ||
            (chatInfo && chats?.length === 0) ||
            (chats?.length === 1 &&
              chats[0].message_type === "SENT_TEXT" &&
              chats[0].text === "")) && (
            <SendWinkBar
              config={sayHalloList}
              handleWink={(wink) => {
                onChangeSendMessage({
                  filename: "",
                  message_content: wink?.message_content,
                  message_type: "SENT_WINK",
                });
              }}
            />
          )}
        </>
      </PageWrap>
      {!chatInfo?.femaleBlock && (
        <Box align={"center"} className={classes.wrpaMessageSenderBox}>
          <Box flex>
            <button
              className={classes.stickerTogleBtn}
              onClick={() => {
                setTogleIsOpenStickersBox(!togleIsOpenStickersBox);
              }}
            >
              <Image
                name="c3-msg-sticker-violet"
                bgImg
                classNameIcon="btnIco"
              />
            </button>
            <InputModern
              isTextArea={"small"}
              infoText={`${valueMessage?.length} / 300`}
              onClick={() => {
                setTogleIsOpenStickersBox(false);
              }}
              onChange={handleValueMessage}
              placeholder={"Type in to chat..."}
              value={valueMessage}
              className={classes.messageInput}
            />
          </Box>
          <Box flex justify={"between"} align={"center"}>
            <Box flex justify={"between"} align={"center"}>
              <button
                className={classes.btnSendPhoto}
                onClick={handleOpenUpploadFile}
              >
                <Image
                  name="attach-ico-blue"
                  imgRotate={45}
                  bgImg
                  classNameIcon="btnIco"
                />
                <p className={classes.textBtnScrep}>Add files</p>
              </button>
              {!womanData?.isLike && (
                <Button
                  text="Like"
                  fitContent
                  isWhite
                  iconSvg="chat-like"
                  bgImg
                  classNameIcon={classes.like}
                  onClick={onChangeSetLike}
                />
              )}
            </Box>
            <Box flex justify={"end"} align={"center"} marginTop={"10px"}>
              <Timeonchat text={`${price?.SENT_TEXT} credits each minute`} />
              <Button
                onClick={() => onChangeSendMessage()}
                text="Send"
                className={classes.sendBtn}
              />
            </Box>
          </Box>
          {togleIsOpenStickersBox && (
            <StickersGroup
              stickersConfig={stickersConfig}
              onChangeStiker={onChangeStiker}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default MessagerPage;
