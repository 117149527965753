import { useState } from "react";
import {
  ModalWindowModern,
  ModalChangeMail,
  CloseButton,
} from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";
import { MIRROR } from "data/mirrorConfig";

// import cn from "classnames";

// @ts-ignore
import classes from "./BanerConfirmMail.module.scss";

interface IProps {
  closeModal?: any;
  modalIsOpen?: any;
}

const BanerConfirmMail = ({ modalIsOpen, closeModal }: IProps) => {
  const [isOpenModalChangeMail, setIsOpenModalChangeMail] = useState(false);
  return (
    <>
      <ModalWindowModern
        modalIsOpen={modalIsOpen}
        hideCloseButton
        closeModal={closeModal}
      >
        <div className={classes.wrap}>
          <CloseButton onClick={closeModal} />
          <Image name="logo-modal" className={classes.logo} />
          <p className={classes.text}>
            Welcome to {/* @ts-ignore */}
            {MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME}!
          </p>
          <p className={classes.description}>
            Confirm your email to receive
            {
              // @ts-ignore
              MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_MIRROR_NAME
            }{" "}
            regular discounts
          </p>
          <button
            className={classes.btnConfirm}
            onClick={() => {
              setIsOpenModalChangeMail(true);
            }}
          >
            Confirm
          </button>
        </div>
      </ModalWindowModern>
      {isOpenModalChangeMail && (
        <ModalChangeMail
          modalIsOpen={isOpenModalChangeMail}
          closeModal={() => {
            setIsOpenModalChangeMail(false);
          }}
        />
      )}
    </>
  );
};

export default BanerConfirmMail;
